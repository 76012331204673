//app.js
var hours = 0.2; // Reset when storage is more than 24hours
var now = new Date().getTime();
var setupTime = localStorage.getItem('setupTime');
if (setupTime == null) {1
    localStorage.setItem('setupTime', now)
} else {
    if(now-setupTime > hours*60*60*1000) {
        localStorage.clear()
        localStorage.setItem('setupTime', now);
    }
}
$(function() {
    $('a[href^=#]').click(function() {
        var speed = 400;
        var href = $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top;
        $('body,html').animate({
            scrollTop: position
        }, speed, 'swing');
        return false;
    });
});



$(document).ready(function() {
    //
    // $(function(){
    // 			 var $elem = $('p.text');         // The element or elements with the text to hide
    // 			 var $limit = 7;        // The number of characters to show
    // 			 var $str = $elem.html();    // Getting the text
    // 			 var $strtemp = $str.substr(0,$limit);   // Get the visible part of the string
    // 			 $str = $strtemp + '<span class="hide">' + $str.substr($limit,$str.length) + '</span>';  // Recompose the string with the span tag wrapped around the hidden part of it
    // 			 $elem.html($str);       // Write the string to the DOM
    // 	 })

    function getParameterByName(name, url) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    var lang = getParameterByName('lang');
    var sesLang = localStorage.getItem("lang");
    //$('.lang-btn a.'+lang ).addClass('active');

    if (lang != '' && lang != null) {
        $('.lang-btn a').removeClass('active');
        $('.lang-btn a.' + lang).addClass('active');
        localStorage.setItem("lang", lang);
    } else {
        if (sesLang != '' && sesLang != null && sesLang != undefined) {
            lang = sesLang;
            $('.lang-btn a').removeClass('active');
            $('.lang-btn a.' + lang).addClass('active');
            localStorage.setItem("lang", lang);
        } else {
            $('.lang-btn a').removeClass('active');
            $('.lang-btn a.jp').addClass('active');
            localStorage.setItem("lang", "ja");
        }
    }
    //
    if (lang != '' && lang != null) {
        $('div .lang-btn a').removeClass('active');
        $('div .lang-btn a.' + lang).addClass('active');
        localStorage.setItem("lang", lang);
    } else {
        if (sesLang != '' && sesLang != null && sesLang != undefined) {
            lang = sesLang;
            $('div .lang-btn a').removeClass('active');
            $('div .lang-btn a.' + lang).addClass('active');
            localStorage.setItem("lang", lang);
        } else {
            $('div .lang-btn a').removeClass('active');
            $('div .lang-btn a.jp').addClass('active');
            localStorage.setItem("lang", "ja");
        }
			}
 //console.log(lang);
    $('#hamburger').on('click', function() {
        $('#hamburger').toggleClass('is-active');
        $('.nav-main').toggleClass('nav-main-is-active');
        $('.lang-box').toggleClass('lang-box-is-active');
        $('.nav-wrapper').toggleClass('nav-wrapper-is-active');
    });

    $('.main-slide').slick({
        dots: true,
        infinite: true,
        speed: 1500,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 2500,
        arrows: true
            // , centerMode: true
            // , adaptiveHeight: true
            // , variableWidth: true
    });

    $('.normal-slide').slick({
        dots: false,
        infinite: true,
        speed: 1500,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 2500,
        arrows: false
            // , centerMode: true
            // , adaptiveHeight: true
            // , variableWidth: true
    });

});
